/* ---------------------------------- COMMON ---------------------------------- */
// LOGIN
export const LOGIN = `LOGIN`
export const LOGIN_SUCCESS = `LOGIN_SUCCESS`
export const LOGIN_ERROR = `LOGIN_ERROR`
export const LOGIN_EMPTY = `LOGIN_EMPTY`

// LOGOUT
export const LOGOUT = `LOGOUT`
export const LOGOUT_SUCCESS = `LOGOUT_SUCCESS`
export const LOGOUT_ERROR = `LOGOUT_ERROR`

// DUMP
export const DOWNLOAD_DUMP = `DOWNLOAD_DUMP`
export const DOWNLOAD_DUMP_SUCCESS = `DOWNLOAD_DUMP_SUCCESS`
export const DOWNLOAD_DUMP_ERROR = `DOWNLOAD_DUMP_ERROR`

// MAILS
export const SEND_MAIL = `SEND_MAIL`
export const SEND_MAIL_SUCCESS = `SEND_MAIL_SUCCESS`
export const SEND_MAIL_ERROR = `SEND_MAIL_ERROR`

// GOLF COURSES
export const GET_GOLF_COURSES = `GET_GOLF_COURSES`
export const GET_GOLF_COURSES_SUCCESS = `GET_GOLF_COURSES_SUCCESS`
export const GET_GOLF_COURSES_ERROR = `GET_GOLF_COURSES_ERROR`

// PROJECTS
export const GET_PROJECTS = `GET_PROJECTS`
export const GET_PROJECTS_SUCCESS = `GET_PROJECTS_SUCCESS`
export const GET_PROJECTS_ERROR = `GET_PROJECTS_ERROR`

/* ---------------------------------- FEATURES ---------------------------------- */
/* ----------------- GOLF BOOKING ----------------- */

// GET GOLF BOOKINGS
export const GET_GOLF_BOOKING = `GET_GOLF_BOOKING`
export const GET_GOLF_BOOKING_SUCCESS = `GET_GOLF_BOOKING_SUCCESS`
export const GET_GOLF_BOOKING_ERROR = `GET_GOLF_BOOKING_ERROR`

// GET GOLF BOOKING BY BOOKING ID
export const GET_GOLF_BOOKING_BY_ID = `GET_GOLF_BOOKING_BY_ID`
export const GET_GOLF_BOOKING_BY_ID_SUCCESS = `GET_GOLF_BOOKING_BY_ID_SUCCESS`
export const GET_GOLF_BOOKING_BY_ID_ERROR = `GET_GOLF_BOOKING_BY_ID_ERROR`

// UPDATE GOLF BOOKING
export const UPDATE_GOLF_BOOKING = `UPDATE_GOLF_BOOKING`
export const UPDATE_GOLF_BOOKING_SUCCESS = `UPDATE_GOLF_BOOKING_SUCCESS`
export const UPDATE_GOLF_BOOKING_ERROR = `UPDATE_GOLF_BOOKING_ERROR`
export const EMPTY_UPDATE_GOLF_BOOKING = `EMPTY_UPDATE_GOLF_BOOKING`

/* ----------------- SUPPLY STORE - COMMON ----------------- */
// GET REDEMPTION TYPE
export const GET_REDEMPTION_TYPE = `GET_REDEMPTION_TYPE`
export const GET_REDEMPTION_TYPE_SUCCESS = `GET_REDEMPTION_TYPE_SUCCESS`
export const GET_REDEMPTION_TYPE_ERROR = `GET_REDEMPTION_TYPE_ERROR`

// GET PARENT CATEGORY
export const GET_PARENT_CATEGORY = `GET_PARENT_CATEGORY`
export const GET_PARENT_CATEGORY_SUCCESS = `GET_PARENT_CATEGORY_SUCCESS`
export const GET_PARENT_CATEGORY_ERROR = `GET_PARENT_CATEGORY_ERROR`

/* ----------------- SUPPLY STORE - CATEGORY ----------------- */
// CREATE
export const CREATE_CATEGORY = `CREATE_CATEGORY`
export const CREATE_CATEGORY_SUCCESS = `CREATE_CATEGORY_SUCCESS`
export const CREATE_CATEGORY_ERROR = `CREATE_CATEGORY_ERROR`

// UPDATE
export const UPDATE_CATEGORY = `UPDATE_CATEGORY`
export const UPDATE_CATEGORY_SUCCESS = `UPDATE_CATEGORY_SUCCESS`
export const UPDATE_CATEGORY_ERROR = `UPDATE_CATEGORY_ERROR`

// GET LIST
export const GET_CATEGORY = `GET_CATEGORY`
export const GET_CATEGORY_SUCCESS = `GET_CATEGORY_SUCCESS`
export const GET_CATEGORY_ERROR = `GET_CATEGORY_ERROR`

/* ----------------- SUPPLY STORE - LOCATION ----------------- */
// CREATE
export const CREATE_LOCATION = `CREATE_LOCATION`
export const CREATE_LOCATION_SUCCESS = `CREATE_LOCATION_SUCCESS`
export const CREATE_LOCATION_ERROR = `CREATE_LOCATION_ERROR`

// UPDATE
export const UPDATE_LOCATION = `UPDATE_LOCATION`
export const UPDATE_LOCATION_SUCCESS = `UPDATE_LOCATION_SUCCESS`
export const UPDATE_LOCATION_ERROR = `UPDATE_LOCATION_ERROR`

// GET LIST
export const GET_LOCATION = `GET_LOCATION`
export const GET_LOCATION_SUCCESS = `GET_LOCATION_SUCCESS`
export const GET_LOCATION_ERROR = `GET_LOCATION_ERROR`

/* ----------------- SUPPLY STORE - PARTNER ----------------- */
// CREATE
export const CREATE_PARTNER = `CREATE_PARTNER`
export const CREATE_PARTNER_SUCCESS = `CREATE_PARTNER_SUCCESS`
export const CREATE_PARTNER_ERROR = `CREATE_PARTNER_ERROR`

// UPDATE
export const UPDATE_PARTNER = `UPDATE_PARTNER`
export const UPDATE_PARTNER_SUCCESS = `UPDATE_PARTNER_SUCCESS`
export const UPDATE_PARTNER_ERROR = `UPDATE_PARTNER_ERROR`

// GET LIST
export const GET_PARTNER = `GET_PARTNER`
export const GET_PARTNER_SUCCESS = `GET_PARTNER_SUCCESS`
export const GET_PARTNER_ERROR = `GET_PARTNER_ERROR`

/* ----------------- SUPPLY STORE - SERVICE ----------------- */
// CREATE
export const CREATE_SERVICE = `CREATE_SERVICE`
export const CREATE_SERVICE_SUCCESS = `CREATE_SERVICE_SUCCESS`
export const CREATE_SERVICE_ERROR = `CREATE_SERVICE_ERROR`

// UPDATE
export const UPDATE_SERVICE = `UPDATE_SERVICE`
export const UPDATE_SERVICE_SUCCESS = `UPDATE_SERVICE_SUCCESS`
export const UPDATE_SERVICE_ERROR = `UPDATE_SERVICE_ERROR`

// GET LIST
export const GET_SERVICE = `GET_SERVICE`
export const GET_SERVICE_SUCCESS = `GET_SERVICE_SUCCESS`
export const GET_SERVICE_ERROR = `GET_SERVICE_ERROR`

/* ----------------- FACILITY ----------------- */
// GET LIST
export const GET_FACILITY = `GET_FACILITY`
export const GET_FACILITY_SUCCESS = `GET_FACILITY_SUCCESS`
export const GET_FACILITY_ERROR = `GET_FACILITY_ERROR`

/* ----------------- GET PARTNER ----------------- */
// GET LIST
export const GET_COUPON_PARTNER = `GET_COUPON_PARTNER`
export const GET_COUPON_PARTNER_SUCCESS = `GET_COUPON_PARTNER_SUCCESS`
export const GET_COUPON_PARTNER_ERROR = `GET_COUPON_PARTNER_ERROR`

/* ----------------- GET PROJECTS ----------------- */
// GET LIST
export const GET_COUPON_PROJECTS = `GET_COUPON_PROJECTS`
export const GET_COUPON_PROJECTS_SUCCESS = `GET_COUPON_PROJECTS_SUCCESS`
export const GET_COUPON_PROJECTS_ERROR = `GET_COUPON_PROJECTS_ERROR`

/* ----------------- GET SERVICE ----------------- */
// GET LIST
export const GET_COUPON_SERVICE = `GET_COUPON_SERVICE`
export const GET_COUPON_SERVICE_SUCCESS = `GET_COUPON_SERVICE_SUCCESS`
export const GET_COUPON_SERVICE_ERROR = `GET_COUPON_SERVICE_ERROR`

/* ----------------- GET COUPON ----------------- */
// GET LIST
export const GET_COUPON = `GET_COUPON`
export const GET_COUPON_SUCCESS = `GET_COUPON_SUCCESS`
export const GET_COUPON_ERROR = `GET_COUPON_ERROR`
