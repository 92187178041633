import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb } from '../../../../Common'
import { Container } from 'reactstrap'

import ServiceForm from './Components/Form'

const AddService = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = (data) => {
    console.log('main', data)
  }

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    console.log({ title })
    if (title === `Dashboard`) {
      history.push('/dashboard')
    } else if (title === `Add Service`) {
      // do nothing
    } else if (title === `Service`) {
      // do nothing
    }
  }

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <>
          <BreadCrumb
            title="Service"
            navigationby={breadcrumNavigationHandler}
            navigation={['Dashboard', 'Service', 'Add Service']}
          />
          <ServiceForm onSubmit={submitHandler} />
        </>
      </Container>
    </div>
  )
}

export default AddService
