import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";
import { LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* userLogin({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/login`,
      method: "POST",
      data: payload,
      headers: { ...commonHeaders },
    });


    if (response?.status === 200) {
      yield put({ type: LOGIN_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: LOGIN_ERROR, payload: response.error });
    }
    // yield put({ type: LOGIN_SUCCESS, payload: response })
  } catch (error) {
    yield put({ type: LOGIN_ERROR, payload: error });
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN, userLogin);
}

function* userLoginSaga() {
  yield all([fork(watchUserLogin)]);
}

export default userLoginSaga;
