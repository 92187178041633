import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../../fetch'
import {
  baseURL,
  commonHeaders,
} from './../../../../../Containers/Confiq/index'

import {
  GET_REDEMPTION_TYPE,
  GET_REDEMPTION_TYPE_SUCCESS,
  GET_REDEMPTION_TYPE_ERROR,
} from './../../../actionType'
import fetchBooking from './../../../fetch'

function* getRedemptionType({ payload }) {
  try {
    const response = yield call(fetchBooking, {
      url: `http://localhost:8083/admin/redemption-types`,
      method: 'GET',
      // headers: { ...commonHeaders },
    })

    console.log('mytestingResponse', response, response?.error)
    if (response?.error === undefined) {
      yield put({ type: GET_REDEMPTION_TYPE_SUCCESS, payload: response })
    } else {
      yield put({ type: GET_REDEMPTION_TYPE_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: GET_REDEMPTION_TYPE_ERROR, payload: error })
  }
}

export function* watchGetRedemptionTypeSaga() {
  yield takeEvery(GET_REDEMPTION_TYPE, getRedemptionType)
}

function* getRedemptionTypeSaga() {
  yield all([fork(watchGetRedemptionTypeSaga)])
}

export default getRedemptionTypeSaga
