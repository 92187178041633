import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb } from '../../../../Common'
import { Container } from 'reactstrap'
import AddCategoryForm from './Components/Form'

import {
  CREATE_CATEGORY,
  GET_PARENT_CATEGORY,
  GET_REDEMPTION_TYPE,
} from '../../../../../store/application/actionType'

const AddCategory = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- REDUX STATES ---------------------------- */
  const {
    parentCategory,
    parentCategoryLoading,
    parentCategoryError,
  } = useSelector((state) => ({
    parentCategory: state?.GetParentCategory?.data,
    parentCategoryLoading: state?.GetParentCategory?.loading,
    parentCategoryError: state?.GetParentCategory?.error,
  }))

  const {
    redemptionType,
    redemptionTypeLoading,
    redemptionTypeError,
  } = useSelector((state) => ({
    redemptionType: state?.GetRedemptionType?.data,
    redemptionTypeLoading: state?.GetRedemptionType?.loading,
    redemptionTypeError: state?.GetRedemptionType?.error,
  }))

  /* ---------------------------- DISPATCHING ---------------------------- */
  useEffect(() => {
    dispatch({
      type: GET_PARENT_CATEGORY,
    })
    dispatch({
      type: GET_REDEMPTION_TYPE,
    })
  }, [dispatch])

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = (data) => {
    dispatch({
      type: CREATE_CATEGORY,
      payload: {
        payloadData: data,
      },
    })
  }

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    if (title === `Dashboard`) {
      history.push('/dashboard')
    } else if (title === `Add Category`) {
      // do nothing
    } else if (title === `Category`) {
      // do nothing
    }
  }
  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <BreadCrumb
          title="Category"
          navigationby={breadcrumNavigationHandler}
          navigation={['Dashboard', 'Category', 'Add Category']}
        />
        {/* <button
          onClick={() => {
            dispatch({
              type: GET_REDEMPTION_TYPE,
            })
          }}
        >
          Get State
        </button> */}
        <AddCategoryForm
          onSubmit={submitHandler}
          parentCategory={parentCategory}
          redemptionType={redemptionType}
        />
      </Container>
    </div>
  )
}

export default AddCategory
