import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Navdata = () => {
  const history = useHistory()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isApps, setIsApps] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const [isPages, setIsPages] = useState(false)
  const [isBaseUi, setIsBaseUi] = useState(false)
  const [isAdvanceUi, setIsAdvanceUi] = useState(false)
  const [isForms, setIsForms] = useState(false)
  const [isTables, setIsTables] = useState(false)
  const [isCharts, setIsCharts] = useState(false)
  const [isIcons, setIsIcons] = useState(false)
  const [isMaps, setIsMaps] = useState(false)
  const [isMultiLevel, setIsMultiLevel] = useState(false)

  // Apps
  const [isEmail, setEmail] = useState(false)
  const [isSubEmail, setSubEmail] = useState(false)
  const [isEcommerce, setIsEcommerce] = useState(false)
  const [isProjects, setIsProjects] = useState(false)
  const [isTasks, setIsTasks] = useState(false)
  const [isCRM, setIsCRM] = useState(false)
  const [isCrypto, setIsCrypto] = useState(false)
  const [isInvoices, setIsInvoices] = useState(false)
  const [isSupportTickets, setIsSupportTickets] = useState(false)
  const [isNFTMarketplace, setIsNFTMarketplace] = useState(false)

  // Authentication
  const [isSignIn, setIsSignIn] = useState(false)
  const [isSignUp, setIsSignUp] = useState(false)
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [isPasswordCreate, setIsPasswordCreate] = useState(false)
  const [isLockScreen, setIsLockScreen] = useState(false)
  const [isLogout, setIsLogout] = useState(false)
  const [isSuccessMessage, setIsSuccessMessage] = useState(false)
  const [isVerification, setIsVerification] = useState(false)
  const [isError, setIsError] = useState(false)

  // Pages
  const [isProfile, setIsProfile] = useState(false)
  const [isLanding, setIsLanding] = useState(false)

  // Charts
  const [isApex, setIsApex] = useState(false)

  // Multi Level
  const [isLevel1, setIsLevel1] = useState(false)
  const [isLevel2, setIsLevel2] = useState(false)

  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  // Supply Store
  const [isPartner, setIsPartner] = useState(false)
  const [isCategory, setIsCategory] = useState(false)
  const [isLocation, setIsLocation] = useState(false)
  const [isService, setIsService] = useState(false)

  // Coupon Management
  const [isCoupon, setIsCoupon] = useState(false)

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        var id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false)
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false)
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false)
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false)
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false)
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false)
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false)
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false)
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false)
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false)
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false)
    }
    if (iscurrentState === 'Widgets') {
      history.push('/widgets')
      document.body.classList.add('twocolumn-panel')
    }
    if (iscurrentState !== 'Landing') {
      setIsLanding(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ])

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'ri-dashboard-2-line',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('Dashboard')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'analytics',
          label: 'Golf Booking',
          link: '/golf-booking',
          parentId: 'dashboard',
        },
        // {
        //   id: 'fab',
        //   label: 'Fab',
        //   link: '/fab',
        //   parentId: 'dashboard',
        // },
      ],
    },

    // {
    //   id: 'supply-store',
    //   label: 'Supply Store',
    //   icon: 'ri-dashboard-2-line',
    //   link: '/#',
    //   click: function (e) {
    //     e.preventDefault()
    //     setIsAuth(!isAuth)
    //     setIscurrentState('Auth')
    //     updateIconSidebar(e)
    //   },
    //   stateVariables: isAuth,
    //   subItems: [
    //     {
    //       id: 'category-management',
    //       label: 'Category',
    //       link: '/#',
    //       isChildItem: true,
    //       click: function (e) {
    //         e.preventDefault()
    //         setIsCategory(!isCategory)
    //       },
    //       parentId: 'supply-store',
    //       stateVariables: isCategory,
    //       childItems: [
    //         { id: 1, label: 'Add Category', link: '/category/add-category' },
    //         {
    //           id: 2,
    //           label: 'Manage Category',
    //           link: '/category/manage-category',
    //         },
    //       ],
    //     },
    //     {
    //       id: 'partner-management',
    //       label: 'Partner',
    //       link: '/#',
    //       isChildItem: true,
    //       click: function (e) {
    //         e.preventDefault()
    //         setIsPartner(!isPartner)
    //       },
    //       parentId: 'supply-store',
    //       stateVariables: isPartner,
    //       childItems: [
    //         { id: 1, label: 'Add Patner', link: '/partner/add-partner' },
    //         {
    //           id: 2,
    //           label: 'Manage Patner',
    //           link: '/partner/manage-partner',
    //         },
    //       ],
    //     },
    //     {
    //       id: 'location-management',
    //       label: 'Location',
    //       link: '/#',
    //       isChildItem: true,
    //       click: function (e) {
    //         e.preventDefault()
    //         setIsLocation(!isLocation)
    //       },
    //       parentId: 'supply-store',
    //       stateVariables: isLocation,
    //       childItems: [
    //         { id: 1, label: 'Add Location', link: '/location/add-location' },
    //         {
    //           id: 2,
    //           label: 'Manage Location',
    //           link: '/location/manage-location',
    //         },
    //       ],
    //     },
    //     {
    //       id: 'service-management',
    //       label: 'Service',
    //       link: '/#',
    //       isChildItem: true,
    //       click: function (e) {
    //         e.preventDefault()
    //         setIsService(!isService)
    //       },
    //       parentId: 'supply-store',
    //       stateVariables: isService,
    //       childItems: [
    //         { id: 1, label: 'Add Service', link: '/service/add-service' },
    //         {
    //           id: 2,
    //           label: 'Manage Service',
    //           link: '/service/manage-service',
    //         },
    //       ],
    //     },
    //   ],
    // },

    // {
    //   id: 'coupon',
    //   label: 'Coupon Management',
    //   icon: 'ri-dashboard-2-line',
    //   link: '/coupon',
    //   stateVariables: isCoupon,
    //   click: function (e) {
    //     e.preventDefault()
    //     setIsCoupon(!isCoupon)
    //     updateIconSidebar(e)
    //   },
    // },
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
