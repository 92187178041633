import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb, PreviewCardHeader } from '../../../../../Common'
import {
  Card,
  CardBody,
  Col,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
} from 'reactstrap'

import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const ServiceForm = () => {
  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [state, setState] = useState({
    projectName: '',
    golfCourse: '',
    fromDate: '',
    toDate: '',
    status: '',
  })

  const inputChangeHandler = (e) => {
    const { name, value } = e.target
    console.log({ name, value })

    switch (name) {
      case 'partner':
        setState({ ...state })
        break

      case 'name':
        setState({ ...state })
        break

      case 'display-name':
        break

      case 'display-logo':
        break

      case 'cover-img-mobile-url':
        break

      case 'cover-img-web-url':
        break

      case 'display-img-mobile-url':
        break

      case 'display-img-web-url':
        break

      case 'third-party-website-url':
        break

      case 'minimum-tat':
        break

      case 'maximum-tat':
        break

      case 'cancellation-tat':
        break

      case 'redemption-type':
        break

      case 'tax-type':
        break

      case 'currency':
        break

      case 'maximum-tat-unit':
        break

      case 'minimum-tat-unit':
        break

      case 'cancellation-tat-unit':
        break

      case 'short-description':
        break

      case 'long-description':
        break

      case 'redemption-process':
        break

      case 'tandc':
        break

      case 'benefit-offered':
        break

      case 'is-mql-active':
        break

      case 'alert-level-1':
        break

      case 'alert-level-2':
        break

      case 'alert-level-3':
        break

      case 'cost-price':
        break

      case 'selling-price':
        break

      case 'tax-percentage':
        break

      default:
      // do nothing
    }
  }

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    console.log('submit', state)
  }

  return (
    <Row>
      <Col xxl={12}>
        <Card>
          <PreviewCardHeader title="Add Service" />
          <CardBody>
            <div className="live-preview">
              <Form>
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="partner" className="form-label">
                        Partner
                      </Label>
                      <select
                        id="partner"
                        name="partner"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="facility" className="form-label">
                        Facility
                      </Label>
                      <select
                        id="facility"
                        name="facility"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="name" className="form-label">
                        Name
                      </Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="display-name" className="form-label">
                        Display Name
                      </Label>
                      <Input
                        id="display-name"
                        name="display-name"
                        type="text"
                        placeholder="Display Name"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="display-logo" className="form-label">
                        Logo
                      </Label>
                      <Input
                        id="display-logo"
                        name="display-logo"
                        type="text"
                        placeholder="Logo"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cover-img-mobile-url"
                        className="form-label"
                      >
                        Cover Image Mobile URL
                      </Label>
                      <Input
                        id="cover-img-mobile-url"
                        name="cover-img-mobile-url"
                        type="text"
                        placeholder="Cover Image Mobile URL"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="cover-img-web-url" className="form-label">
                        Cover Image Web URL
                      </Label>
                      <Input
                        id="cover-img-web-url"
                        name="cover-img-web-url"
                        type="text"
                        placeholder="Cover Image Web URL"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="display-img-mobile-url"
                        className="form-label"
                      >
                        Display Image Mobile URL
                      </Label>
                      <Input
                        id="display-img-mobile-url"
                        name="display-img-mobile-url"
                        type="text"
                        placeholder="Display Image Mobile URL"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="display-img-web-url"
                        className="form-label"
                      >
                        Display Image Web URL
                      </Label>
                      <Input
                        id="display-img-web-url"
                        name="display-img-web-url"
                        type="text"
                        placeholder="Display Image Web URL"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="third-party-website-url"
                        className="form-label"
                      >
                        Default Third Party Website URL
                      </Label>
                      <Input
                        id="third-party-website-url"
                        name="third-party-website-url"
                        type="text"
                        placeholder="Thrid Party Website URL"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="minimum-tat" className="form-label">
                        Default Minimum TAT
                      </Label>
                      <Input
                        id="minimum-tat"
                        name="minimum-tat"
                        type="text"
                        placeholder="Minimum TAT"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="maximum-tat" className="form-label">
                        Default Maximum TAT
                      </Label>
                      <Input
                        id="maximum-tat"
                        name="maximum-tat"
                        type="text"
                        placeholder="Maximum TAT"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="cancellation-tat" className="form-label">
                        Default Cancellation TAT
                      </Label>
                      <Input
                        id="cancellation-tat"
                        name="cancellation-tat"
                        type="text"
                        placeholder="Cancellation TAT"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="redemption-type" className="form-label">
                        Redemption Type
                      </Label>
                      <select
                        id="redemption-type"
                        name="redemption-type"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="tax-type" className="form-label">
                        Default TAX Type
                      </Label>
                      <select
                        id="tax-type"
                        name="tax-type"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="currency" className="form-label">
                        Default Currency
                      </Label>
                      <select
                        id="currency"
                        name="currency"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="maximum-tat-unit" className="form-label">
                        Default Maximum TAT Unit
                      </Label>
                      <select
                        id="maximum-tat-unit"
                        name="maximum-tat-unit"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="minimum-tat-unit" className="form-label">
                        Default Minimum TAT Unit
                      </Label>
                      <select
                        id="minimum-tat-unit"
                        name="minimum-tat-unit"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label
                        htmlFor="cancellation-tat-unit"
                        className="form-label"
                      >
                        Default Cancellation TAT Unit
                      </Label>
                      <select
                        id="cancellation-tat-unit"
                        name="cancellation-tat-unit"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="short-description" className="form-label">
                        Short Description
                      </Label>
                      <textarea
                        id="short-description"
                        name="short-description"
                        className="form-control"
                        rows="3"
                        onChange={inputChangeHandler}
                      ></textarea>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="mb-3">
                      <Label htmlFor="long-description" className="form-label">
                        Long Description
                      </Label>
                      <textarea
                        id="long-description"
                        name="long-description"
                        className="form-control"
                        rows="3"
                        onChange={inputChangeHandler}
                      ></textarea>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="redemption-process"
                        className="form-label"
                      >
                        Default Redemption Process
                      </Label>
                      <div className="snow-editor" style={{ height: 300 }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={''}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(editor) => {
                            editor.getData()
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="tandc" className="form-label">
                        Default T&C
                      </Label>
                      <div className="snow-editor" style={{ height: 300 }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={''}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(editor) => {
                            editor.getData()
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <Label htmlFor="benefit-offered" className="form-label">
                        Default Benefit Offered
                      </Label>
                      <div className="snow-editor" style={{ height: 300 }}>
                        {/* <div ref={quillRef2} /> */}
                        <CKEditor
                          editor={ClassicEditor}
                          data={''}
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                          }}
                          onChange={(editor) => {
                            editor.getData()
                          }}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <Input
                          role="switch"
                          type="checkbox"
                          id="is-mql-active"
                          name="is-mql-active"
                          className="form-check-input"
                          // defaultChecked={hasParentCategory}
                          onChange={inputChangeHandler}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="is-mql-active"
                        >
                          Is MQL active ?
                        </Label>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="alert-level-1" className="form-label">
                        Alert Level 1
                      </Label>
                      <select
                        id="alert-level-1"
                        name="alert-level-1"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="alert-level-2" className="form-label">
                        Alert Level 2
                      </Label>
                      <select
                        id="alert-level-2"
                        name="alert-level-2"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="alert-level-3" className="form-label">
                        Alert Level 3
                      </Label>
                      <select
                        id="alert-level-3"
                        name="alert-level-3"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={12}>
                    <div className="mb-3">
                      <div className="form-check form-switch">
                        <Input
                          role="switch"
                          type="checkbox"
                          id="have-to-create-login"
                          name="have-to-create-login"
                          className="form-check-input"
                          // defaultChecked={hasParentCategory}
                          onChange={inputChangeHandler}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="have-to-create-login"
                        >
                          Create Login ?
                        </Label>
                      </div>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="login" className="form-label">
                        Login I'd
                      </Label>
                      <select
                        id="login"
                        name="login"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="password" className="form-label">
                        Password
                      </Label>
                      <select
                        id="password"
                        name="password"
                        className="form-select"
                        // value={filter.projectName}
                        onChange={inputChangeHandler}
                      >
                        <option value="">Choose...</option>
                      </select>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="cost-price" className="form-label">
                        Cost Price
                      </Label>
                      <Input
                        id="cost-price"
                        name="cost-price"
                        type="text"
                        placeholder="Cost Price"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="selling-price" className="form-label">
                        Cost Price
                      </Label>
                      <Input
                        id="selling-price"
                        name="selling-price"
                        type="text"
                        placeholder="Selling Price"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="mb-3">
                      <Label htmlFor="tax-percentage" className="form-label">
                        Tax Percentage
                      </Label>
                      <Input
                        id="tax-percentage"
                        name="tax-percentage"
                        type="text"
                        placeholder="Tax Percentage"
                        className="form-control"
                        onChange={inputChangeHandler}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md={6} className="p-0">
                <div className="start"></div>
              </Col>

              <Col md={6}>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-primary mx-3 overlay-disable"
                    onClick={submitHandler}
                    // disabled={isFilterDisabled}
                  >
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  )
}

export default ServiceForm
