/* ---------------------------- PRODUCTION ---------------------------- */

// // API base URL
// export const baseURL = `https://prod-mastercard-api.thriwe.com`

// // Common headers
// export const commonHeaders = {
//   'x-parse-application-id': 'PROD_APPLICATION_ID',
//   'x-parse-master-key': 'PROD_MASTER_KEY',
//   'Content-Type': 'application/json',
//   // 'Accept-Encoding': 'gzip, deflate, br',
//   Accept: '*/*',
// }

/* ---------------------------- STAGING ---------------------------- */
// API base URL
export const baseURL = process.env.REACT_APP_PROJECTS_URL;
export const bookingsBaseURL =  process.env.REACT_APP_BOOKINGS_URL;

// Common headers
export const commonHeaders = {
  "x-parse-application-id": process.env.REACT_APP_PARSE_APPLICATION_ID,
  "x-parse-master-key": process.env.REACT_APP_PARSE_MASTER_KEY,
  "Content-Type": "application/json",
  // 'Accept-Encoding': 'gzip, deflate, br',
  Accept: "*/*",
};
