import axios from "axios";

export default async function apiCall(config) {
  const response = await axios({
    method: `${config.method}`,
    url: `${config.url}`,
    headers: {
      ...config.headers,
    },
    params: config?.params,
    data: config.isFormData ? config.data : JSON.stringify(config.data),
    // paramsSerializer: customParamsSerializer,
  })
    .then(function (response) {
      return response;
    })
    .catch((err) => {
      return err;
    });
  return response;
}
