import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../fetch'
import { baseURL, commonHeaders } from '../../../../Containers/Confiq/index.js'

import {
  GET_FACILITY,
  GET_FACILITY_SUCCESS,
  GET_FACILITY_ERROR,
} from './../../actionType.js'
import fetchBooking from './../../fetch'

function* getFacility({ payload }) {
  try {
    const response = yield call(fetchBooking, {
      url: `${baseURL}/parse/classes/golfbookings/?where=${payload.param}`,
      method: 'GET',
      // headers: { ...commonHeaders },
    })

    if (response?.error === undefined) {
      yield put({ type: GET_FACILITY_SUCCESS, payload: response })
    } else {
      yield put({ type: GET_FACILITY_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: GET_FACILITY_ERROR, payload: error })
  }
}

export function* watchGetFacility() {
  yield takeEvery(GET_FACILITY, getFacility)
}

function* getCouponFacilitySaga() {
  yield all([fork(watchGetFacility)])
}

export default getCouponFacilitySaga
