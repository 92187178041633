import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { PreviewCardHeader } from '../../../../../Common'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
} from 'reactstrap'
import { map } from 'leaflet'

const AddCategoryForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [hasParentCategory, setHasParentCategory] = useState(false)
  const [state, setState] = useState({
    name: '',
    displayName: '',
    parentCategory: {
      id: '',
      name: '',
    },
    Images: {
      logo: '',
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
    longDescription: '',
    shortDescription: '',
    defaultRedemptionType: {
      id: '',
      name: '',
    },
  })

  const inputChangeHandler = (e) => {
    const { name, value } = e.target

    switch (name) {
      case 'has-parent-category':
        hasParentCategory &&
          setState({
            ...state,
            parentCategory: {
              ...state.parentCategory,
              id: '',
            },
          })
        setHasParentCategory(!hasParentCategory)
        break

      case 'parent-category':
        setState({
          ...state,
          parentCategory: {
            ...state.parentCategory,
            id: value,
          },
        })
        break

      case 'name':
        setState({ ...state, name: value })
        break

      case 'display-name':
        setState({ ...state, displayName: value })
        break

      case 'logo':
        setState({
          ...state,
          Images: {
            ...state.Images,
            logo: value,
          },
        })
        break

      case 'cover-img-mobile-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            coverImageMobile: value,
          },
        })
        break

      case 'cover-img-web-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            coverImageWeb: value,
          },
        })
        break

      case 'display-img-mobile-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            displayImageMobile: value,
          },
        })
        break

      case 'display-img-web-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            displayImageWeb: value,
          },
        })
        break

      case 'redemption-type':
        setState({ ...state })
        setState({
          ...state,
          defaultRedemptionType: {
            ...state.defaultRedemptionType,
            id: value,
          },
        })
        break

      case 'short-description':
        setState({ ...state, shortDescription: value })
        break

      case 'long-description':
        setState({ ...state, longDescription: value })
        break

      default:
      // do nothing
    }
  }

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const redemptionIndex = props?.redemptionType?.findIndex(
      (redemption) => redemption.objectId === state?.defaultRedemptionType?.id,
    )

    const parentCategoryIndex = props?.parentCategory?.findIndex(
      (category) => category.objectID == state?.parentCategory?.id,
    )

    let customState = {
      ...state,
      defaultRedemptionType: {
        ...state.defaultRedemptionType,
        name: props.redemptionType[redemptionIndex]?.name,
      },
      parentCategory: {
        ...state.parentCategory,
        name: props.parentCategory[parentCategoryIndex]?.name,
      },
    }
    props.onSubmit(customState)
  }

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Add Category" />
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="form-check form-switch">
                          <Input
                            role="switch"
                            type="checkbox"
                            id="has-parent-category"
                            name="has-parent-category"
                            className="form-check-input"
                            defaultChecked={hasParentCategory}
                            onChange={inputChangeHandler}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="has-parent-category"
                          >
                            Has it Parent Category
                          </Label>
                        </div>
                      </div>
                    </Col>

                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <select
                            name="parent-category"
                            className="form-select"
                            disabled={!hasParentCategory}
                            value={state.parentCategory.id}
                            onChange={inputChangeHandler}
                          >
                            <option value="">Choose...</option>
                            {props?.parentCategory?.map((category, index) => {
                              return (
                                <option key={index} value={category.objectID}>
                                  {category?.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="Name"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.name}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="display-name" className="form-label">
                          Display Name
                        </Label>
                        <Input
                          id="display-name"
                          name="display-name"
                          type="text"
                          placeholder="Display Name"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.displayName}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="logo" className="form-label">
                          Logo
                        </Label>
                        <Input
                          id="logo"
                          name="logo"
                          type="text"
                          placeholder="URL"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.Images.logo}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="cover-img-mobile-url"
                          className="form-label"
                        >
                          Cover Image Mobile URL
                        </Label>
                        <Input
                          id="cover-img-mobile-url"
                          name="cover-img-mobile-url"
                          type="text"
                          placeholder="URL"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.Images.coverImageMobile}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="cover-img-web-url"
                          className="form-label"
                        >
                          Cover Image Web URL
                        </Label>
                        <Input
                          id="cover-img-web-url"
                          name="cover-img-web-url"
                          type="text"
                          placeholder="URL"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.Images.coverImageWeb}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="display-img-mobile-url"
                          className="form-label"
                        >
                          Display Image Mobile URL
                        </Label>
                        <Input
                          id="display-img-mobile-url"
                          name="display-img-mobile-url"
                          type="text"
                          placeholder="URL"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.Images.displayImageMobile}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="display-img-web-url"
                          className="form-label"
                        >
                          Display Image Web URL
                        </Label>
                        <Input
                          id="display-img-web-url"
                          name="display-img-web-url"
                          type="text"
                          placeholder="URL"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.Images.displayImageWeb}
                        />
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="redemption-type" className="form-label">
                          Redemption Type
                        </Label>
                        <select
                          id="redemption-type"
                          name="redemption-type"
                          className="form-select"
                          onChange={inputChangeHandler}
                          value={state.defaultRedemptionType.id}
                        >
                          <option value="">Choose...</option>
                          {props?.redemptionType?.map((redemption, index) => {
                            return (
                              <option key={index} value={redemption?.objectId}>
                                {redemption?.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="short-description"
                          className="form-label"
                        >
                          Short Description
                        </Label>
                        <textarea
                          id="short-description"
                          name="short-description"
                          className="form-control"
                          rows="3"
                          onChange={inputChangeHandler}
                          value={state.shortDescription}
                        ></textarea>
                      </div>
                    </Col>

                    <Col md={6}>
                      <div className="mb-3">
                        <Label
                          htmlFor="long-description"
                          className="form-label"
                        >
                          Long Description
                        </Label>
                        <textarea
                          id="long-description"
                          name="long-description"
                          className="form-control"
                          rows="3"
                          onChange={inputChangeHandler}
                          value={state.longDescription}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-primary mx-3 overlay-disable"
                      onClick={submitHandler}
                      // disabled={isFilterDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AddCategoryForm
