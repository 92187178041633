import { all, fork } from 'redux-saga/effects'
//layout
import LayoutSaga from './layouts/saga'

//Auth
import AccountSaga from './auth/register/saga'
import AuthSaga from './auth/login/saga'
import ForgetSaga from './auth/forgetpwd/saga'
import ProfileSaga from './auth/profile/saga'

//calendar
import calendarSaga from './calendar/saga'

//chat
import chatSaga from './chat/saga'

//ecommerce
import ecommerceSaga from './ecommerce/saga'

//Project
import projectSaga from './projects/saga'

// Task
import taskSaga from './tasks/saga'

// Crypto
import cryptoSaga from './crypto/saga'

//TicketsList
import ticketsSaga from './tickets/saga'

//crm
import crmSaga from './crm/saga'

//invoice
import invoiceSaga from './invoice/saga'

//mailbox
import mailboxSaga from './mailbox/saga'

/* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

// User Login
import userLoginSaga from './application/common/login/saga'

// User Logout
import userLogoutSaga from './application/common/logout/saga'

// Mail
import sendMailSaga from './application/common/mail/saga'

// All Projects
import allProjectsSaga from './application/common/projects/saga'

// All Golf Courses
import allGolfCoursesSaga from './application/common/golfcourses/saga'

// Download Dump
import DownloadDump from './application/common/downloadDump/saga'

// Golf Booking
import golfBookingsSaga from './application/golf/golfbooking/saga'
import updateGolfBookingSaga from './application/golf/updategolfbooking/saga'
import getGolfBookingById from './application/golf/getGolfBookingById/saga'

// Supply store - Common
import getRedemptionTypeSaga from './application/supplyStore/common/getRedemptionType/saga'
import getParentCategorySaga from './application/supplyStore/common/getParentCategory/saga'

// Supply store - Category
import createCategorySaga from './application/supplyStore/category/createCategory/saga'
import updateCategorySaga from './application/supplyStore/category/updateCategory/saga'
import getCategorySaga from './application/supplyStore/category/listCategory/saga'

// Supply store - Location
import createLocationSaga from './application/supplyStore/Location/createLocation/saga'
import updateLocationSaga from './application/supplyStore/Location/updateLocation/saga'
import getLocationSaga from './application/supplyStore/Location/listLocation/saga'

// Supply store - Partner
import createPartnerSaga from './application/supplyStore/Partner/createPartner/saga'
import updatePartnerSaga from './application/supplyStore/Partner/updatePartner/saga'
import getPartnerSaga from './application/supplyStore/Partner/listPartner/saga'

// Supply store - Service
import createServiceSaga from './application/supplyStore/Service/createService/saga'
import updateServiceSaga from './application/supplyStore/Service/updateService/saga'
import getServiceSaga from './application/supplyStore/Service/listService/saga'

// Coupon Management
import getCouponSaga from './application/coupon/getCoupon/saga'
import getCouponFacilitySaga from './application/coupon/facility/saga'
import getCouponPartnerSaga from './application/coupon/partner/saga'
import getCouponProjectSaga from './application/coupon/projects/saga'
import getCouponServiceSaga from './application/coupon/service/saga'

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(chatSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(cryptoSaga),
    fork(ticketsSaga),
    fork(calendarSaga),
    fork(ecommerceSaga),
    fork(crmSaga),
    fork(invoiceSaga),
    fork(mailboxSaga),

    /* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

    fork(allProjectsSaga),
    fork(allGolfCoursesSaga),
    fork(userLoginSaga),
    fork(golfBookingsSaga),
    fork(updateGolfBookingSaga),
    fork(userLogoutSaga),
    fork(sendMailSaga),
    fork(getGolfBookingById),
    fork(DownloadDump),

    // Supply store - Common
    fork(getRedemptionTypeSaga),
    fork(getParentCategorySaga),

    // Supply store - Category
    fork(createCategorySaga),
    fork(updateCategorySaga),
    fork(getCategorySaga),

    // Supply store - Location
    fork(createLocationSaga),
    fork(updateLocationSaga),
    fork(getLocationSaga),

    // Supply store - Partner
    fork(createPartnerSaga),
    fork(updatePartnerSaga),
    fork(getPartnerSaga),

    // Supply store - Service
    fork(createServiceSaga),
    fork(updateServiceSaga),
    fork(getServiceSaga),

    // Coupon Management
    getCouponSaga,
    getCouponFacilitySaga,
    getCouponPartnerSaga,
    getCouponProjectSaga,
    getCouponServiceSaga,
  ])
}
