import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../../fetch'
import {
  baseURL,
  commonHeaders,
} from './../../../../../Containers/Confiq/index'

import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_ERROR,
} from './../../../actionType'
import fetchBooking from './../../../fetch'

function* createCategory({ payload }) {
  try {
    const response = yield call(fetchBooking, {
      url: `http://localhost:8083/admin/categories`,
      method: 'POST',
      data: payload.payloadData,
      // headers: { ...commonHeaders },
    })

    console.log('mytestingResponse', response, response?.error)
    if (response?.error === undefined) {
      yield put({ type: CREATE_CATEGORY_SUCCESS, payload: response })
    } else {
      yield put({ type: CREATE_CATEGORY_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: CREATE_CATEGORY_ERROR, payload: error })
  }
}

export function* watchCreateCategorySaga() {
  yield takeEvery(CREATE_CATEGORY, createCategory)
}

function* createCategorySaga() {
  yield all([fork(watchCreateCategorySaga)])
}

export default createCategorySaga
