import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../../fetch'
import {
  baseURL,
  commonHeaders,
} from './../../../../../Containers/Confiq/index'

import {
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_ERROR,
} from './../../../actionType'

function* updatePartner({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${baseURL}/parse/classes/golfbookings/${payload.id}`,
      method: 'GET',
      headers: { ...commonHeaders },
    })

    console.log('mytestingResponse', response, response?.error)
    if (response?.error === undefined) {
      yield put({ type: UPDATE_PARTNER_SUCCESS, payload: response })
    } else {
      yield put({ type: UPDATE_PARTNER_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: UPDATE_PARTNER_ERROR, payload: error })
  }
}

export function* watchUpdatePartner() {
  yield takeEvery(UPDATE_PARTNER, updatePartner)
}

function* updatePartnerSaga() {
  yield all([fork(watchUpdatePartner)])
}

export default updatePartnerSaga
