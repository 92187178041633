import React, { useEffect, useState } from 'react'

import { Grid, _ } from 'gridjs-react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

const Table = (props) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data) => {
          return [
            data?.objectID,
            data?.displayName,
            data?.shortDescription,
            data?.longDescription,
          ]
        }),
      )
    }
  }, [props?.data])

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  {props.headingPreview}
                </h4>
              </CardHeader>

              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={data}
                    columns={[
                      {
                        name: 'ID',
                        width: '170px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Name',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Short Description',
                        width: '120px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Long Description',
                        width: '150px',
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: 'Actions',
                        width: '120px',
                        formatter: (cell, row) =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              <span
                                className="btn btn-sm btn-primary mb-1"
                                onClick={() =>
                                  console.log(`${row.cells[0].data}`)
                                }
                              >
                                Edit
                              </span>
                            </div>,
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Table
