import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";
import { LOGOUT, LOGOUT_SUCCESS, LOGOUT_ERROR } from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* userLogout() {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/logout`,
      method: "POST",
      headers: {
        ...commonHeaders,
        "x-parse-session-token": `${
          JSON.parse(sessionStorage.getItem("authUser")).token
        }`,
      },
    });

    if (response?.status === 200) {
      yield put({ type: LOGOUT_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: LOGOUT_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, payload: error });
  }
}
export function* watchUserLogout() {
  yield takeEvery(LOGOUT, userLogout);
}

function* userLogoutSaga() {
  yield all([fork(watchUserLogout)]);
}

export default userLogoutSaga;
