import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";

import {
  GET_PROJECTS,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_ERROR,
} from "./../../actionType.js";

import fetch from "./../../fetch";
import cogoToast from "cogo-toast";
import apiCall from "./../../fetch";

function* getProjectsList() {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/classes/tenants`,
      method: "GET",
      headers: { ...commonHeaders },
    });

    console.log("responsee", response);

    if (response?.status === 200) {
      yield put({ type: GET_PROJECTS_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: GET_PROJECTS_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_PROJECTS_ERROR, payload: error });
  }
}
export function* watchGetGolfProjectsList() {
  yield takeEvery(GET_PROJECTS, getProjectsList);
}

function* allProjectsSaga() {
  yield all([fork(watchGetGolfProjectsList)]);
}

export default allProjectsSaga;
