import React, { useEffect, useState } from 'react'

import { Grid, _ } from 'gridjs-react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { PreviewCardHeader } from '../../../Common'

const Table = (props) => {
  const [data, setData] = useState([
    [1, 'Rahul', 'Short description1', 'Long Description'],
    [2, 'Rahul1', 'Short description2', 'Long Description1'],
    [3, 'Rahul2', 'Short description3', 'Long Description2'],
  ])

  useEffect(() => {
    if (props?.data?.length > 0) {
      setData(
        props?.data?.map((data) => {
          return [
            data?.objectID,
            data?.displayName,
            data?.shortDescription,
            data?.longDescription,
          ]
        }),
      )
    }
  }, [props?.data])

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Coupon Data" />
            <CardBody>
              <div id="table-gridjs">
                <Grid
                  data={data}
                  columns={[
                    {
                      name: 'ID',
                      width: '170px',
                      formatter: (cell) => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Name',
                      width: '120px',
                      formatter: (cell) => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Short Description',
                      width: '120px',
                      formatter: (cell) => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Long Description',
                      width: '150px',
                      formatter: (cell) => _(<span className="">{cell}</span>),
                    },

                    {
                      name: 'Actions',
                      width: '120px',
                      formatter: (cell, row) =>
                        _(
                          <div className="d-flex justify-content-center flex-column">
                            <span
                              className="btn btn-sm btn-primary mb-1"
                              onClick={() =>
                                console.log(`${row.cells[0].data}`)
                              }
                            >
                              Edit
                            </span>
                          </div>,
                        ),
                    },
                  ]}
                  search={false}
                  sort={false}
                  fixedHeader={true}
                  height="400px"
                  pagination={{ enabled: false, limit: 5 }}
                />
              </div>
              {props.children}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Table
