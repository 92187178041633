import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";
import {
  UPDATE_GOLF_BOOKING,
  UPDATE_GOLF_BOOKING_SUCCESS,
  UPDATE_GOLF_BOOKING_ERROR,
} from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* updateGolfBooking({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/classes/golfbookings/${payload.bookingId}`,
      method: "PUT",
      data: payload.payloadData,
      headers: { ...commonHeaders },
    });
    console.log("response", response);
    if (response?.status === 200) {
      yield put({ type: UPDATE_GOLF_BOOKING_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: UPDATE_GOLF_BOOKING_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: UPDATE_GOLF_BOOKING_ERROR, payload: error });
  }
}

export function* watchUpdateGolfBooking() {
  yield takeEvery(UPDATE_GOLF_BOOKING, updateGolfBooking);
}

function* updateGolfBookingSaga() {
  yield all([fork(watchUpdateGolfBooking)]);
}

export default updateGolfBookingSaga;
