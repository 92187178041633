import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb, PreviewCardHeader } from '../../../../Common'
import { Table } from './Components/index'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
} from 'reactstrap'
import { GET_CATEGORY } from '../../../../../store/application/actionType'

const ManageCategory = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- REDUX STATES ---------------------------- */
  const { categories, categoriesLoading, categoriesError } = useSelector(
    (state) => ({
      categories: state?.GetCategory?.data,
      categoriesLoading: state?.GetCategory?.loading,
      categoriesError: state?.GetCategory?.error,
    }),
  )

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    console.log({ title })
    if (title === `Dashboard`) {
      history.push('/dashboard')
    } else if (title === `Add Category`) {
      // do nothing
    } else if (title === `Category`) {
      // do nothing
    }
  }

  const DUMMY_DATA = [
    {
      objectId: '0Kt3GcIkMB',
      user: {
        id: 'ODF62lbPh9',
        first_name: 'Suraj',
        last_name: 'Kumar',
        email: 'suraj.kumar@thriwe.com',
        phone: '1234567',
        country_code: '+1264',
        handicap: '2',
      },
      golf_course: {
        preferred: {
          id: 'j0f3EHnf40',
          name: 'Karma Lakelands',
          type: '',
          region: {
            id: '',
            name: 'Gurgaon',
          },
          country: {
            id: 'XywCvKF1z1',
            name: 'India',
          },
          address:
            'Karma Lakelands Pvt. Ltd, Sector-80, Naurangpur, Gurugram, Haryana 122002',
          description:
            'Deserted away from the chaotic clatters of the urban surrounding of the Delhi NCR is located a haven on earth for all the golf lovers out there. Spread across 300 acres of lush green meadows, lakes & lawns, the Karma Lakelands is a true symbol of exotic golfing. Strategically designed by Pacific Coast Design, Melbourne, Australia, The Karma Lakelands Comprises of an award winning 9-hole golf course that will give you an 18-hole experience. Also offering a number of world class facilities like pizza cafe, bars, private dining, pro shop & large areas that can host weddings, corporate events as well as shoots.',
        },
      },
      date_of_play: {
        preferred: '2022/09/28',
      },
      time_of_play: {
        preferred: '06:40',
      },
      delayed_cancel_tat: 96,
      delayed_cancel_tat_unit: 'Hours',
      source: 'Website',
      order_id: 'mastercard_ngKxQYYYo4ryKg',
      status: 'CANCELLED BY THRIWE',
      payment: {
        card: {
          first_six: '521063',
          last_four: '1009',
        },
        green_fees: {
          initial: {
            amount: '30.00',
            currency: 'INR',
          },
          final: {
            amount: '30.00',
            currency: 'INR',
          },
        },
        discount: {
          initial: {
            amount: '9.00',
            currency: 'INR',
            percentage: 30,
          },
          final: {
            amount: '9.00',
            currency: 'INR',
            percentage: 30,
          },
        },
        processing_fees: {
          initial: {
            amount: '100.00',
            currency: 'INR',
          },
          final: {
            amount: '100.00',
            currency: 'INR',
          },
        },
        sub_total: {
          initial: {
            amount: '21.00',
            currency: 'INR',
          },
          final: {
            amount: '21.00',
            currency: 'INR',
          },
        },
        total_payable: {
          initial: {
            amount: '124.78',
            currency: 'INR',
          },
          final: {
            amount: '124.78',
            currency: 'INR',
          },
        },
        booking_fees: {
          amount: '0',
          currency: '',
        },
        taxes: {
          initial: {
            amount: '3.78',
            currency: 'INR',
          },
          final: {
            amount: '3.78',
            currency: 'INR',
          },
        },
      },
      createdAt: '2022-09-14T11:29:28.378Z',
      updatedAt: '2022-09-21T10:04:02.527Z',
      transactionObjectId: 'KQehfiKa1P',
      isBookingEmailSent: true,
      remarks: {
        admin_cancellation: 'Tournament at the golf course',
      },
    },
  ]

  useEffect(() => {
    dispatch({
      type: GET_CATEGORY,
    })
  }, [dispatch])

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <>
          <BreadCrumb
            title="Category"
            navigationby={breadcrumNavigationHandler}
            navigation={['Dashboard', 'Category', 'Add Category']}
          />
          <Table data={categories} />
        </>
      </Container>
    </div>
  )
}

export default ManageCategory
