import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";
import {
  GET_GOLF_COURSES,
  GET_GOLF_COURSES_SUCCESS,
  GET_GOLF_COURSES_ERROR,
} from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* getGolfCoursesList({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/classes/golfcourses/`,
      method: "GET",
      headers: { ...commonHeaders },
    });

    console.log("response g", response);

    if (response?.status === 200) {
      yield put({ type: GET_GOLF_COURSES_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: GET_GOLF_COURSES_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_GOLF_COURSES_ERROR, payload: error });
  }
}

export function* watchGetGolfBookingList() {
  yield takeEvery(GET_GOLF_COURSES, getGolfCoursesList);
}

function* allGolfCoursesSaga() {
  yield all([fork(watchGetGolfBookingList)]);
}

export default allGolfCoursesSaga;
