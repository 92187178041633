import React from 'react'
import { Redirect } from 'react-router-dom'

import {
  Dashboard,
  GolfBooking,
  Login,
  Home,
  Fab,
} from './../Containers/Pages/index'

// import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import AddCategory from '../Containers/Pages/SupplyStore/Category/AddCategory'
import ManageCategory from '../Containers/Pages/SupplyStore/Category/ManageCategory'
import AddLocation from '../Containers/Pages/SupplyStore/Location/AddLocation'
import ManageLocation from '../Containers/Pages/SupplyStore/Location/ManageLocation'
import AddPartner from '../Containers/Pages/SupplyStore/Partner/AddPartner'
import ManagePartner from '../Containers/Pages/SupplyStore/Partner/ManagePartner'
import AddService from '../Containers/Pages/SupplyStore/Service/AddService'
import ManageService from '../Containers/Pages/SupplyStore/Service/ManageService'
import CouponManagement from '../Containers/Pages/Coupon'

const publicRoutes = [
  { path: '/login', component: Login },
  { path: '/logout', component: Logout },
  // { path: '/', component: Home },
]

const authProtectedRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/golf-booking', component: GolfBooking },
  { path: '/fab', component: Fab },
  { path: '/', component: Home },

  // Supply Store
  { path: '/category', component: AddCategory },
  { path: '/category/add-category', component: AddCategory },
  { path: '/category/manage-category', component: ManageCategory },

  { path: '/partner/add-partner', component: AddPartner },
  { path: '/partner/manage-partner', component: ManagePartner },

  { path: '/location/add-location', component: AddLocation },
  { path: '/location/manage-location', component: ManageLocation },

  { path: '/service/add-service', component: AddService },
  { path: '/service/manage-service', component: ManageService },

  // Coupon Management
  { path: '/coupon', component: CouponManagement },
]

export { authProtectedRoutes, publicRoutes }
