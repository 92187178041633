import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { PreviewCardHeader } from './../../../Common'
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  CardFooter,
} from 'reactstrap'

const AddCategoryForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- LOCAL STATES ---------------------------- */
  const [hasParentCategory, setHasParentCategory] = useState(false)
  const [state, setState] = useState({
    name: '',
    displayName: '',
    parentCategory: {
      id: '',
      name: '',
    },
    Images: {
      logo: '',
      coverImageMobile: '',
      coverImageWeb: '',
      displayImageMobile: '',
      displayImageWeb: '',
    },
    longDescription: '',
    shortDescription: '',
    defaultRedemptionType: {
      id: '',
      name: '',
    },
  })

  const inputChangeHandler = (e) => {
    const { name, value } = e.target

    switch (name) {
      case 'has-parent-category':
        hasParentCategory &&
          setState({
            ...state,
            parentCategory: {
              ...state.parentCategory,
              id: '',
            },
          })
        setHasParentCategory(!hasParentCategory)
        break

      case 'parent-category':
        setState({
          ...state,
          parentCategory: {
            ...state.parentCategory,
            id: value,
          },
        })
        break

      case 'name':
        setState({ ...state, name: value })
        break

      case 'display-name':
        setState({ ...state, displayName: value })
        break

      case 'logo':
        setState({
          ...state,
          Images: {
            ...state.Images,
            logo: value,
          },
        })
        break

      case 'cover-img-mobile-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            coverImageMobile: value,
          },
        })
        break

      case 'cover-img-web-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            coverImageWeb: value,
          },
        })
        break

      case 'display-img-mobile-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            displayImageMobile: value,
          },
        })
        break

      case 'display-img-web-url':
        setState({
          ...state,
          Images: {
            ...state.Images,
            displayImageWeb: value,
          },
        })
        break

      case 'redemption-type':
        setState({ ...state })
        setState({
          ...state,
          defaultRedemptionType: {
            ...state.defaultRedemptionType,
            id: value,
          },
        })
        break

      case 'short-description':
        setState({ ...state, shortDescription: value })
        break

      case 'long-description':
        setState({ ...state, longDescription: value })
        break

      default:
      // do nothing
    }
  }

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = () => {
    const redemptionIndex = props?.redemptionType?.findIndex(
      (redemption) => redemption.objectId === state?.defaultRedemptionType?.id,
    )

    const parentCategoryIndex = props?.parentCategory?.findIndex(
      (category) => category.objectID == state?.parentCategory?.id,
    )

    let customState = {
      ...state,
      defaultRedemptionType: {
        ...state.defaultRedemptionType,
        name: props.redemptionType[redemptionIndex]?.name,
      },
      parentCategory: {
        ...state.parentCategory,
        name: props.parentCategory[parentCategoryIndex]?.name,
      },
    }
    props.onSubmit(customState)
  }

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <PreviewCardHeader title="Filter" />
            <CardBody>
              <div className="live-preview">
                <Form>
                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="display-name" className="form-label">
                          Uploaded By
                        </Label>
                        <select
                          id="redemption-type"
                          name="redemption-type"
                          className="form-select"
                          onChange={inputChangeHandler}
                          value={state.defaultRedemptionType.id}
                        >
                          <option value="">Choose...</option>
                          {props?.redemptionType?.map((redemption, index) => {
                            return (
                              <option key={index} value={redemption?.objectId}>
                                {redemption?.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="display-name" className="form-label">
                          Assigned Status
                        </Label>
                        <select
                          id="redemption-type"
                          name="redemption-type"
                          className="form-select"
                          onChange={inputChangeHandler}
                          value={state.defaultRedemptionType.id}
                        >
                          <option value="">Choose...</option>
                          {props?.redemptionType?.map((redemption, index) => {
                            return (
                              <option key={index} value={redemption?.objectId}>
                                {redemption?.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </Col>

                    <Col md={4}>
                      <div className="mb-3">
                        <Label htmlFor="name" className="form-label">
                          Date
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="date"
                          placeholder="Name"
                          className="form-control"
                          onChange={inputChangeHandler}
                          value={state.name}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
              <Row>
                <Col md={6} className="p-0">
                  <div className="start"></div>
                </Col>

                <Col md={6}>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="btn btn-primary mx-3 overlay-disable"
                      onClick={submitHandler}
                      // disabled={isFilterDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AddCategoryForm
