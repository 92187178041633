import {
  GET_PARENT_CATEGORY,
  GET_PARENT_CATEGORY_SUCCESS,
  GET_PARENT_CATEGORY_ERROR,
} from './../../../actionType.js'

const initialState = {
  data: null,
  message: null,
  loading: false,
  success: false,
  error: false,
}

const GetParentCategory = (state = initialState, action) => {
  console.log('result', { state, action: action.type })
  switch (action.type) {
    case GET_PARENT_CATEGORY:
      state = {
        ...state,
        data: null,
        loading: true,
        success: false,
        error: false,
        message: null,
      }
      break

    case GET_PARENT_CATEGORY_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        success: true,
        error: false,
        message: null,
      }
      break

    case GET_PARENT_CATEGORY_ERROR:
      state = {
        ...state,
        data: null,
        loading: false,
        success: false,
        error: true,
        message: action.payload,
      }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default GetParentCategory
