import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL,bookingsBaseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";

import {
  GET_GOLF_BOOKING,
  GET_GOLF_BOOKING_SUCCESS,
  GET_GOLF_BOOKING_ERROR,
} from "./../../actionType.js";
import apiCall from "./../../fetch";
import cogoToast from "cogo-toast";

function* getGolfBooking({ payload }) {
  try {
    // const baseURL= "https://staging-tgpl-crm-api.thriwe.com"
    const response = yield call(apiCall, {
      url: `${bookingsBaseURL}/parse/classes/golfbookings/?where=${payload?.param}`,
      method: "GET",
      headers: { ...commonHeaders },
    });

    console.log("response", response);
    if (response?.status === 200) {
      yield put({ type: GET_GOLF_BOOKING_SUCCESS, payload: response });
    } else {
      cogoToast?.error(response?.response?.data?.error);
      yield put({ type: GET_GOLF_BOOKING_ERROR, payload: response.error });
    }
  } catch (error) {
    yield put({ type: GET_GOLF_BOOKING_ERROR, payload: error });
  }
}

export function* watchGolfBooking() {
  yield takeEvery(GET_GOLF_BOOKING, getGolfBooking);
}

function* golfBookingsSaga() {
  yield all([fork(watchGolfBooking)]);
}

export default golfBookingsSaga;
