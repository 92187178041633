import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../../fetch'
import {
  baseURL,
  commonHeaders,
} from './../../../../../Containers/Confiq/index'

import {
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_ERROR,
} from './../../../actionType'

function* getPartner({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${baseURL}/parse/classes/golfbookings/${payload.id}`,
      method: 'GET',
      headers: { ...commonHeaders },
    })

    console.log('mytestingResponse', response, response?.error)
    if (response?.error === undefined) {
      yield put({ type: GET_PARTNER_SUCCESS, payload: response })
    } else {
      yield put({ type: GET_PARTNER_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: GET_PARTNER_ERROR, payload: error })
  }
}

export function* watchGetPartner() {
  yield takeEvery(GET_PARTNER, getPartner)
}

function* getPartnerSaga() {
  yield all([fork(watchGetPartner)])
}

export default getPartnerSaga
