import { combineReducers } from 'redux'

// Front
import Layout from './layouts/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'
import Profile from './auth/profile/reducer'

//Calendar
import Calendar from './calendar/reducer'
//Chat
import chat from './chat/reducer'
//Ecommerce
import Ecommerce from './ecommerce/reducer'

//Project
import Projects from './projects/reducer'

// Tasks
import Tasks from './tasks/reducer'
//Form advanced
import changeNumber from './formAdvanced/reducer'

//Crypto
import Crypto from './crypto/reducer'

//TicketsList
import Tickets from './tickets/reducer'
//Crm
import Crm from './crm/reducer'

//Invoice
import Invoice from './invoice/reducer'

//Mailbox
import Mailbox from './mailbox/reducer'

/* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

// USER LOGIN
import UserLogin from './application/common/login/reducer'

// USER LOGOUT
import UserLogout from './application/common/logout/reducer'

// Mail
import SendMail from './application/common/mail/reducer'

// All Projects
import AllProjects from './application/common/projects/reducer'

// All Golf Courses
import AllGolfCourses from './application/common/golfcourses/reducer'

// Dump
import DownloadDump from './application/common/downloadDump/reducer'

// Golf Booking
import GolfBookings from './application/golf/golfbooking/reducer'
import UpdateGolfBooking from './application/golf/updategolfbooking/reducer'
import GetGolfBookingById from './application/golf/getGolfBookingById/reducer'

// Supply store - Common
import GetRedemptionType from './application/supplyStore/common/getRedemptionType/reducer'
import GetParentCategory from './application/supplyStore/common/getParentCategory/reducer'

// Supply store - Category
import CreateCategory from './application/supplyStore/category/createCategory/reducer'
import GetCategory from './application/supplyStore/category/listCategory/reducer'
import UpdateCategory from './application/supplyStore/category/updateCategory/reducer'

// Supply store - Location
import CreateLocation from './application/supplyStore/Location/createLocation/reducer'
import GetLocation from './application/supplyStore/Location/listLocation/reducer'
import UpdateLocation from './application/supplyStore/Location/updateLocation/reducer'

// Supply store - Partner
import CreatePartner from './application/supplyStore/Partner/createPartner/reducer'
import GetPartner from './application/supplyStore/Partner/listPartner/reducer'
import UpdatePartner from './application/supplyStore/Partner/updatePartner/reducer'

// Supply store - Service
import CreateService from './application/supplyStore/Service/createService/reducer'
import GetService from './application/supplyStore/Service/listService/reducer'
import UpdateService from './application/supplyStore/Service/updateService/reducer'

// Coupon Management
import GetCoupon from './application/coupon/getCoupon/reducer'
import GetCouponFacility from './application/coupon/facility/reducer'
import GetCouponPartner from './application/coupon/partner/reducer'
import GetCouponProject from './application/coupon/projects/reducer'
import GetCouponServices from './application/coupon/service/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  Crm,
  Invoice,
  Mailbox,

  /* ---------------------------- AFTER CUSTOMIZATION ---------------------------- */

  AllProjects,
  AllGolfCourses,
  UserLogin,
  GolfBookings,
  UpdateGolfBooking,
  UserLogout,
  SendMail,
  GetGolfBookingById,
  DownloadDump,

  // Supply store - Common
  GetParentCategory,
  GetRedemptionType,

  // Supply store - Category
  CreateCategory,
  UpdateCategory,
  GetCategory,

  // Supply store - Location
  CreateLocation,
  UpdateLocation,
  GetLocation,

  // Supply store - Partner
  CreatePartner,
  UpdatePartner,
  GetPartner,

  // Supply store - Service
  CreateService,
  UpdateService,
  GetService,

  // Coupon Management
  GetCoupon,
  GetCouponFacility,
  GetCouponPartner,
  GetCouponProject,
  GetCouponServices,
})

export default rootReducer
