import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import fetch from './../../fetch'
import { baseURL, commonHeaders } from '../../../../Containers/Confiq/index.js'

import {
  GET_COUPON_PROJECTS,
  GET_COUPON_PROJECTS_SUCCESS,
  GET_COUPON_PROJECTS_ERROR,
} from './../../actionType.js'

function* getCouponProject({ payload }) {
  try {
    const response = yield call(fetch, {
      url: `${baseURL}/parse/classes/golfbookings/?where=${payload.param}`,
      method: 'GET',
      headers: { ...commonHeaders },
    })

    if (response?.error === undefined) {
      yield put({ type: GET_COUPON_PROJECTS_SUCCESS, payload: response })
    } else {
      yield put({ type: GET_COUPON_PROJECTS_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: GET_COUPON_PROJECTS_ERROR, payload: error })
  }
}

export function* watchGetCouponProject() {
  yield takeEvery(GET_COUPON_PROJECTS, getCouponProject)
}

function* getCouponProjectSaga() {
  yield all([fork(watchGetCouponProject)])
}

export default getCouponProjectSaga
