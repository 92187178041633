import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { BreadCrumb, PreviewCardHeader } from '../../../../Common'
import { Container } from 'reactstrap'

import LocationForm from './Components/Form'

const AddLocation = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  /* ---------------------------- SUBMIT HANDLER ---------------------------- */
  const submitHandler = (data) => {
    console.log('main', data)
  }

  /* ---------------------------- BREADCRUM HANDLER ---------------------------- */
  const breadcrumNavigationHandler = (title) => {
    console.log({ title })
    if (title === `Dashboard`) {
      history.push('/dashboard')
    } else if (title === `Add Location`) {
      // do nothing
    } else if (title === `Location`) {
      // do nothing
    }
  }

  return (
    <div className="page-content">
      <Container fluid className="position-relative">
        <>
          <BreadCrumb
            title="Location"
            navigationby={breadcrumNavigationHandler}
            navigation={['Dashboard', 'Location', 'Add Location']}
          />
          <LocationForm onSubmit={submitHandler} />
        </>
      </Container>
    </div>
  )
}

export default AddLocation
