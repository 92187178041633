import React from "react";
import ReactPaginate from "react-paginate";

const TablePagination = ({ totalData, currentPage, onPageChangeHandler }) => {
  return (
    <ReactPaginate
      previousLabel={"previous"}
      nextLabel={"next"}
      breakLabel={"..."}
      forcePage={currentPage}
      pageCount={Math.ceil(totalData / 10)}
      marginPagesDisplayed={2}
      pageRangeDisplayed={1}
      onPageChange={(event) => {
        onPageChangeHandler(event.selected);
      }}
      containerClassName={"pagination justify-content-center"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextClassName={"page-item"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
    />
  );
};

export default TablePagination;
