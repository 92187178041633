import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import fetch from "./../../fetch";
import { baseURL, commonHeaders } from "../../../../Containers/Confiq/index.js";

import {
  GET_GOLF_BOOKING_BY_ID,
  GET_GOLF_BOOKING_BY_ID_SUCCESS,
  GET_GOLF_BOOKING_BY_ID_ERROR,
} from "./../../actionType.js";
import apiCall from "./../../fetch";

function* getGolfBookingById({ payload }) {
  try {
    const response = yield call(apiCall, {
      url: `${baseURL}/parse/classes/golfbookings/${payload.id}`,
      method: "GET",
      headers: { ...commonHeaders },
    });

    console.log("mytestingResponse", response, response?.error);
    if (response?.status === 200) {
      yield put({ type: GET_GOLF_BOOKING_BY_ID_SUCCESS, payload: response });
    } else {
      yield put({
        type: GET_GOLF_BOOKING_BY_ID_ERROR,
        payload: response.error,
      });
    }
  } catch (error) {
    yield put({ type: GET_GOLF_BOOKING_BY_ID_ERROR, payload: error });
  }
}

export function* watchGetGolfBookingById() {
  yield takeEvery(GET_GOLF_BOOKING_BY_ID, getGolfBookingById);
}

function* getGolfBookingByIdSaga() {
  yield all([fork(watchGetGolfBookingById)]);
}

export default getGolfBookingByIdSaga;
