import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

import {
  baseURL,
  commonHeaders,
} from './../../../../../Containers/Confiq/index'

import {
  GET_PARENT_CATEGORY,
  GET_PARENT_CATEGORY_SUCCESS,
  GET_PARENT_CATEGORY_ERROR,
} from './../../../actionType'
import fetchBooking from './../../../fetch'

function* getParentCategory({ payload }) {
  try {
    const response = yield call(fetchBooking, {
      url: `http://localhost:8083/admin/categories?parent=true`,
      method: 'GET',
      // headers: { ...commonHeaders },
    })

    console.log('mytestingResponse', response, response?.error)
    if (response?.error === undefined) {
      console.log('inside saga', {
        type: GET_PARENT_CATEGORY_SUCCESS,
        payload: response,
      })
      yield put({ type: GET_PARENT_CATEGORY_SUCCESS, payload: response })
    } else {
      yield put({ type: GET_PARENT_CATEGORY_ERROR, payload: response.error })
    }
  } catch (error) {
    yield put({ type: GET_PARENT_CATEGORY_ERROR, payload: error })
  }
}

export function* watchGetParentCategorySaga() {
  yield takeEvery(GET_PARENT_CATEGORY, getParentCategory)
}

function* getParentCategorySaga() {
  yield all([fork(watchGetParentCategorySaga)])
}

export default getParentCategorySaga
